.emitter-form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.input-wrapper {
  flex-grow: 1;
}

.channel {
  margin-bottom: 15px;
}
