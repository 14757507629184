#root {
  height: 100%;
}

.app {
  display: flex;
  max-height: 100%;
  height: 100%;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  box-shadow: var(--eds-elevation-shadow-4);
  font-family: 'Helvetica Neue', sans-serif;
}

.header {
  height: 7%;
}

.main {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 0.6fr 1fr 0.7fr;
  column-gap: 70px;
  padding: 30px 56px;
}
