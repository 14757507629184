.item {
  padding: 29px 15px;
  border-radius: 15px;
  border: 1px solid var(--eds-color-background-active);
  row-gap: 20px;
  cursor: pointer;
}

.item:hover {
  border: 1px solid var(--eds-color-border-hover);
}

.selected {
  background-color: var(--eds-color-background-active);
}
