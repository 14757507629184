.channels-section {
  display: flex;
  flex-direction: column;
}

.channels-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 60px;
}

.channels-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  max-height: 45vh;
}

.item:hover {
  border: 1px solid var(--eds-color-border-hover);
}

.create-channel-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.modal-button-wrapper {
  display: flex;
  justify-content: space-around;
}
