.item {
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  border-radius: 15px;
  border: 1px solid var(--eds-color-background-active);
  row-gap: 20px;
  cursor: pointer;
}

.selected {
  background-color: var(--eds-color-background-active);
}

.item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-controls-wrapper {
  display: flex;
  gap: 5px;
}

.edit-channel-form {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.input-wrapper {
  flex-grow: 1;
}
